<template>
<div>
    <b-form-group
        :state="jobBranchValidation"
    >
    <v-select
        v-model="selected"
        :label="label"
        :options="options"
        :multiple="multiple"
        :reduce="item => item.id"
        :disabled="disabled"
    />
    </b-form-group>
</div>
</template>
<script>
import vSelect from 'vue-select';
import { BFormGroup } from "bootstrap-vue";
export default {
    name: 'CustomAutopopulate',
    components: { vSelect, BFormGroup },
    props: {
        module: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: 'name',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        retrieve: {
            type: String,
            default: "id_only",
        },
        value: {
            type: Number,
            default: 0,
        },
        jobBranchValidation: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            options: [],
            selected: null,
            disabled: true,
        };
    },
    watch: {
        selected(val) {
            if (this.retrieve === "id_only") {
                this.$emit('changed', val);
            } else {
                const obj = this.options.find(item => item.id === val);
                this.$emit('changed', obj);
            }
        },
    },
    created() {
        console.log(this.value);
        this.selected = this.value;
    },
    mounted() {
        this.$axios.get(`${this.module}/autopopulate`, {
            params: {
                column: this.label,
            },
        }).then((response) => {
            this.options = response.data.results;
            this.disabled = false;
        }).catch((err) => console.error(err));
    },
}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
